.FormModal {
  .ant-modal {
    float: right;
    margin-top: 10px;
    width: 685px !important;
    padding-bottom: 0;

    .ant-modal-content {
      min-height: 95vh;

      .ant-modal-header {
        padding: 0 24px;
        display: flex;
        align-items: center;

        border-bottom: none;
        min-height: 80px;
        background: #451855;

        .ant-modal-title {
          font-family: $font-inter;
          color: white;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
        }
      }

      .ant-modal-close {
        display: none;
      }
    }

    .ant-modal-body {
      padding: 22px 32px 20px 24px;
      margin-bottom: 2rem;

      min-height: calc(95vh - 80px);

      #causes-editor,
      #campaign-settings-editor,
      #search-store,
      #form-add-brand,
      #form-edit-store,
      #form-add-store-to-brand,
      #form-create-collection {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .shopify-id-input {
          .ant-form-item-control {
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                input[disabled] {
                  color: rgba(0, 0, 0, 0.85);
                }
              }
            }
          }
        }

        .ant-form-item {
          margin-bottom: 12px;

          .ant-form-item-label {
            label {
              font-family: $font-inter;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 140%;

              /* identical to box height, or 20px */

              /* Grey / 9 (Dark) */
              color: #040b16;
            }
          }

          .ant-form-item-control {
            .ant-form-item-control-input-content {
              font-family: $font-inter;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 140%;

              color: #b7babd;

              input {
                height: 40px;
              }

              textarea {
                min-height: 104px;
                font-family: $font-inter;
              }

              .ant-select-selector {
                padding-top: 2px;
                height: 40px;
                line-height: 0;
                font-family: $font-inter;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;

                color: rgba(0, 0, 0, 0.85);

                input {
                  height: 40px;
                  line-height: 0;
                }
              }

              p {
                font-family: $font-inter;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 140%;
                margin-bottom: 13px;

                color: #000000;
                display: inline-block;
              }
            }
          }
        }

        .submit-row {
          margin-top: 16%;

          .ant-form-item {
            margin-bottom: 6px;
          }

          .btn {
            &--save {
              background: #5e2eba;
              border-radius: 4px;
              font-family: $font-inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              height: 50px;
              width: 100%;
              line-height: 140%;
              border: none;

              &:disabled {
                color: #f2f2f2;
                background-color: #7e84b1;
              }
            }

            &--save-purple {
              background-color: #451855;
            }

            &--cancel {
              height: 50px;
              width: 100%;
              background: #f2f2f2;
              border-radius: 4px;
              font-family: $font-inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              color: #828282;
              border: none;
            }
          }
        }

        .btn {
          &--search {
            margin-left: 13px;
            background: #5e2eba;
            border-radius: 4px;
            font-family: $font-inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            border: none;
            min-width: 121px;
            height: 40px;

            color: #ffffff;

            &:hover {
              opacity: 0.9;
            }
          }
        }
      }

      #causes-editor,
      #campaign-settings-editor {
        section {
          min-height: 578px;
          div {
            h2 {
              font-family: $font-inter;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 140%;
              margin-bottom: 15px;
              color: #040b16;
            }
            button {
              min-width: 104px;
              height: 32px;
              border: #a76abc;
              background: #a76abc;
              box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
                inset 0px -1px 0px rgba(0, 0, 0, 0.2);
              border-radius: 4px;

              span {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                text-align: center;

                color: #ffffff;
              }
              &:hover {
                opacity: 0.9;
              }
              &.default {
                border: 1px solid black;

                background: #ffffff;

                border-radius: 8px;

                span {
                  color: #000000;
                }
              }
            }
            &.row {
              display: flex;
              justify-content: space-between;
              margin-bottom: 31px;

              h2 {
                font-family: $font-inter;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 140%;

                color: #040b16;
              }
            }
            &.column {
              button {
                border: none;

                background: #a76abc;
                box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
                  inset 0px -1px 0px rgba(0, 0, 0, 0.2);
                border-radius: 4px;

                span {
                  color: white;
                }
              }
            }
            &.selected__causes {
              margin-bottom: 31px;
              min-height: 168px;
            }

            &.search {
              &__row {
                h3 {
                  font-family: $font-inter;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 140%;
                  margin-bottom: 15px;
                  color: #040b16;
                }

                p {
                  font-family: $font-inter;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 11px;
                  line-height: 140%;

                  color: #000000;
                }
              }
            }

            &.cause-selected {
              display: grid;

              align-items: center;
              grid-gap: 10px;
              min-height: 90px;
              padding: 9px 19px 9px 16px;
              border: 1px solid #f0f0f0;
              grid-template-columns: 74px 420px 86px;
              &:hover {
                background-color: #fafafa;
              }
              img {
                width: 40px;
                border-radius: 50%;
                height: 40px;
              }

              h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;

                color: #202223;
              }

              span {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;

                /* or 125% */

                /* Text/Subdued */
                color: #6d7175;
              }

              button {
                border: none;
                background: #a76abc;
                box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
                  inset 0px -1px 0px rgba(0, 0, 0, 0.2);
                border-radius: 4px;
                width: 86px;
                height: 36px;

                span {
                  color: white;
                }
              }
            }
          }
        }
      }

      #search-store {
        height: 528px;
      }

      #form-add-brand {
      }

      #form-create-collection {
        .submit-row {
          margin-top: 32px;
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }
}
