.image_picker {
    width: 100%;

    .responsive {
        position: relative;
        margin: 0 6px;
        float: left;
        opacity: 1;

        .thumbnail {
            border: 1px solid #ddd;
            /* Gray border */
            border-radius: 4px;
            /* Rounded border */
            padding: 3px;
            /* Some padding */
            width: 150px;
            /* Set a small width */
            opacity: 1;
            margin: 0;

            &:hover {
                box-shadow: 0 0 2px 1px #7437e6;
            }
        }

        .selected {
            background-color: #5E2EBA;
        }

        &:hover {
            cursor: pointer;
        }

        .checked {
            display: none;
        }
    }

    .selected {
        opacity: 0.7;

        .checked {
            display: inline-block;
            transition: .5s ease;
            position: absolute;
            bottom: 0;
            right: 0;

            .icon {
                background: url("../../assets/images/check.png");
                background-size: cover;
                height: 75px;
                width: 75px;


                &--75 {
                    width: 35px;
                    height: 35px;
                    bottom: 10px;
                    left: 10px;
                    position: relative;
                }
            }
        }
    }

    .clear {
        clear: both;
    }
}