.store {
  .storeDetail {
    margin: 2rem 0 2.3rem;
    display: grid;
    align-items: center;
    grid-gap: 5.3rem;
    grid-template-columns: 240px auto;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;
    }
    .img {
      &__container {
        max-width: 240px;
        max-height: 240px;
        cursor: pointer;
        position: relative;

        .img {
          height: 240px;
          width: 240px;
          opacity: 1;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;

          &:hover {
            background-color: rgb(0, 0, 0);
            img {
              filter: opacity(28%);
            }
            .inputfile {
              display: flex;
              justify-content: center;
              margin: 5px auto;
              label {
                z-index: 2;
                display: flex;
              }
            }
          }
          img {
            object-fit: contain;
            height: 100%;
            width: 100%;
            max-width: 100%;
            max-width: 100%;
          }
        }

        @media (max-width: 991px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .inputfile {
          display: flex;
          justify-content: center;
          margin: 5px auto;
          label {
            display: none;
            width: auto;
            height: auto;
            cursor: pointer;
            width: 100%;
            height: 100%;
            font-size: 14px;
            font-family: $font-helvetica;
            background: transparent;
            position: absolute;
            top: 0;
            align-items: center;
            justify-content: center;
            margin: auto;
            span {
              svg {
                width: 50px;
                height: 50px;
                color: white;
              }
            }

            input {
              display: none;
            }
          }
        }
      }
    }
    .text {
      &__container {
        .title {
          &__container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;
            h2 {
              font-family: $font-helvetica-neue;
              font-style: normal;
              font-weight: bold;
              font-size: 36px;
              line-height: 44px;

              color: #000000;
            }
            .Btn {
              &__edit {
                font-family: $font-inter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                background-color: transparent;
                cursor: pointer;
                border: none;
                box-shadow: none;
                outline: none;
                text-align: center;
                letter-spacing: 0.75px;
                text-transform: uppercase;
                color: #000000;
              }
            }
          }
        }

        .detail {
          p {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 12px;

            color: #686868;

            span,
            a {
              font-family: $font-helvetica-neue;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 17px;

              color: #000000;
            }

            span {
              margin-left: 47px;
            }
            a {
              text-decoration: underline;
              font-size: 16px;
            }
          }
        }

        p {
        }
      }
    }
  }
}

.store-actions {
  display: flex;
  margin-bottom: 27px;
}

.store-actions__search {
  width: 50%;
}

.tableStores {
  .ant-table-row {
    cursor: pointer;
  }
}
