.ant-pagination {
    .ant-pagination-item-link,
    .ant-pagination-item{
        color:#5e2eba;
    }
    .ant-pagination-item-link:not([disabled]):hover,
    .ant-pagination-item-active,
    .ant-pagination-item:hover {
        color: #5e2eba;
        border-color:#5e2eba;
        font-weight: 600;
        border-width: 1.9px;
        & a {
            color: #5e2eba;
        }
        & :hover,
        a:hover {
            opacity: 0.9;
        }
    }
}
