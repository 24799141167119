.ant-table-container {
  .ant-table-content {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    table {
      thead {
        tr {
          th {
            font-family: $font-inter;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
        }
      }

      tbody {
        tr {
          &.ant-table-measure-row {
            display: none;
          }

          td {
            font-family: $font-helvetica-neue;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;

            padding: 16px;
            /* identical to box height */

            color: #000000;
            a {
              color: #000000;
              text-decoration: underline;
              &:hover {
                opacity: 0.9;
              }
            }
            button {
              background-color: #574867;
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }

  .img-category,
  .img-cause {
    position: relative;
    top: 10px;
    height: auto;
    width: 42px;
  }
  .btn-detail {
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-right: 1rem;
    text-decoration-line: underline;

    color: #000000;
  }
}
.collections {
  .ant-table-container {
    .ant-table-content {
      .ant-table-thead {
        display: none;
      }
      tbody {
        tr {
          td {
            img {
              width: 40px;
              border-radius: 50%;
              height: 40px;
              position: relative;
              top: 1rem;
            }
            h3 {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;

              color: #202223;
            }
            span {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 15px;

              /* or 125% */

              /* Text/Subdued */
              color: #6d7175;
            }
            button {
              border: none;
              background: #451855;
              box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
                inset 0px -1px 0px rgba(0, 0, 0, 0.2);
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              min-width: 59px;
              span {
                color: white;
              }
              height: 36px;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
    }
  }
}
.ant-tooltip-inner {
  font-size: 12px;
  font-family: $font-roboto;
}

.data__table {
  .ant-table-row {
    cursor: pointer;
  }
}
