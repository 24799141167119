.react-responsive-modal-modal {
    @media (min-width: 992px) {
    max-width: 1000px!important;
    }
    border-radius: 8px!important;
    padding: 2rem;
    .react-responsive-modal-closeButton{
        display: none;
        &:active, &:focus{
            outline: none;
        }
    }
}
