.featured-causes-card{
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(66, 71, 76, 0.45);
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 16px;
    display: grid;
    min-height: 88px;
    grid-gap:16px;
    grid-template-columns: 60px auto 127px;
  .picture{
      height: 60px;
      width: 60px;
border-radius: 50%;
    border: 1px solid #CCCCCC;
  }   
  .info{
      h6{
        font-family: $font-helvetica;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        
        /* identical to box height, or 150% */
        
        /* Text/Default */
        color: #202223;
        
        
      }
      span{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        
    
        color: #6D7175;
        
        
      }
  } 
  .button{
     button{
         border:none;
        background: #451855;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        span{
            color:white;
            font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
        }
        &.default{
           border:1px solid black;


            background: #FFFFFF;
           
            border-radius: 8px;

            span{
                color: #000000;


            }
        }
     }
      
  }
}