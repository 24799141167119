.FormEditor {
  .ant-modal {
    float: right;
    margin-top: 10px;
    width: 685px !important;
    padding-bottom: 0;

    .ant-modal-content {
      min-height: 95vh;
      .ant-modal-header {
        padding: 0 24px;
        display: flex;
        align-items: center;

        border-bottom: none;
        min-height: 80px;
        background: #451855;
        .ant-modal-title {
          font-family: $font-inter;
          color: white;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
        }
      }

      .ant-modal-close {
        display: none;
      }
    }
    .ant-modal-body {
      padding: 0 32px 20px 24px;

      min-height: calc(95vh - 80px);
      #form-styling-editor {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        h2 {
          font-family: $font-inter;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 140%;
          margin: 22px 0 16px;

          color: #040b16;
        }
        .ant-form-item {
          margin-bottom: 12px;

          .ant-form-item-label {
            label {
              font-family: $font-helvetica;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              padding: 0 0 4px;
              text-transform: uppercase;

              color: #202223;
            }
          }
          .ant-form-item-control {
            .ant-form-item-control-input-content {
              font-family: $font-inter;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 140%;

              color: #b7babd;
              input {
                height: 40px;

                font-family: $font-inter;

                font-style: normal;
                font-weight: normal;
                font-size: 14px;

                color: #202223;
              }
            }
          }
        }
        .ant-form-item-control-input-content {
          .ant-radio-group {
            display: grid;
            grid-template-columns: 35% auto;
          }
          .ant-radio-wrapper {
            span {
              font-family: $font-inter;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 14px;

              color: #040b16;
              &.ant-radio-inner {
                border-color: #b7babd;
                border-radius: 4px;
                &::after {
                  background-color: #5e2eba;
                }
              }
            }
          }

          input {
            height: 40px;
            font-family: $font-roboto;

            &::placeholder {
              font-family: $font-roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;

              color: #c4c4c4;
            }
          }
        }
        .submit-row {
          margin-top: 8vh;
          .ant-form-item {
            margin-bottom: 6px;
          }
          .btn {
            &--save {
              background: #5e2eba;
              border-radius: 4px;
              font-family: $font-inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              height: 50px;
              width: 100%;
              line-height: 140%;
              border: none;
              &:disabled {
                color: #f2f2f2;
                background-color: #7e84b1;
              }
            }
            &--save-purple {
              background-color: #451855;
            }
            &--cancel {
              height: 50px;
              width: 100%;
              background: #f2f2f2;
              border-radius: 4px;
              font-family: $font-inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              color: #828282;
              border: none;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      display: none;
    }
  }
}
