.Breadcrumb{
    display: inline-block !important;
    &__Link{ 
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    color: black !important;
    cursor: pointer;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
    span{
        color: black;
    }
    }
    &__ProgramProfile{
        margin: 0 0 16px 0;
    }
    .ant-breadcrumb-separator{
        color: black;
    }
}