.block {
  &--row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 2rem 0 3rem;
    @media (min-width: 992px) {
      display: flex;
      flex-direction: row;
      height: 40px;
      align-items: flex-end;
      width: 100%;
      justify-content: space-between;
    }
  }
  &--spaced-inline {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 32px 0 16px;

    & > * {
      flex: 1;
      margin-right: 16px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
}
