.ant-input-search-button{
  background: #5e2eba;
  border-color: #5e2eba;
}
.button {
  &--add {
    min-width: 156px;
    height: 50px;
    font-family: $font-inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    border-radius: 4px;
    line-height: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;

    color: #ffffff;

    background: #5e2eba;
    @media (max-width: 991px) {
      margin-top: 1rem;
    }
    img {
      margin-right: 1rem;
    }
    &:hover,
    &:active,
    &:focus {
      background: #5e2eba;
      color: #ffffff;
      border: none;
      opacity: 0.9;
    }
  }
  &--add-collection{
    color: #ffffff;
    min-width: 248px;
    background: #5e2eba;
    font-family: $font-inter;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 140%;
height: 50px;
border-radius: 4px;
img{
  margin-right: 16px;
}
&:hover, &:active, &:focus{
  background: #5e2eba;
  color: #ffffff;
  border: none;
  opacity: 0.9;
}
  }
}
