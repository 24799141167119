.form-edit-global-rating {
    min-width: 90%;
  @media (min-width: 992px) {
    min-width: 410px;
    margin: 1.8rem 3.6rem 1rem 1.4rem;
  }

  h4 {
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 3.3rem;
    color: #000000;
  }
  .ant-form-item {
    .ant-form-item-label {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      margin-bottom:7px;
      color: #000000;
      margin-left: 10px;
    }
    .ant-form-item-control-input-content {
      .ant-radio-wrapper {
        span {
          font-family: $font-roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;

          color: #5b5b5b;
          &.ant-radio-inner {
            border-color: #5e6871;
            &::after {
              background-color: #5e6871;
            }
          }
        }
      }
      .ant-input-number {
        height: 40px;
        border: 1px solid #c4c4c4;

        &::placeholder {
          font-family: $font-roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;

          color: #c4c4c4;
        }
        input{
          height: 40px;
        }
      }
    }

    .ant-input-number,
    .ant-input-number-focused {
      min-width: 100%;
      &:hover,
      &:active,
      .ant-input-number-focused {
        border-color: rgba(98, 200, 182, 1);
      }
      &:focus,
      .ant-input-number-focused {
        border-color: rgb(244, 250, 249);
        box-shadow: 0 0 0 0.9px rgba(98, 200, 182, 1);
      }

      .ant-input-number-handler-wrap {
        display: none;
      }
    }

    .ant-input-number-focused {
      box-shadow: none;
    }

    label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      color: transparent;
    }
  }
  .submit-row {
    @media (min-width: 992px) {
      display: flex;
      padding-top: 2rem;
    }
    .btn {
      &--save {
        outline: none;
        border: none;
        background: #5e2eba;
        height: 40px;
        width: 100%;
        font-family: $font-helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;

        /* identical to box height */
        text-align: center;

        color: #ffffff;

        @media (min-width: 992px) {
          width: 196px;
        }
      }

      &--cancel {
        cursor: pointer;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        width: 100%;
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        height: 40px;
        line-height: 17px;
        text-decoration-line: underline;

        color: #000000;
        @media (min-width: 992px) {
          margin-left: 3.3rem;
          width: 50px;
        }
      }
    }
  }

  .ant-input-number-input {
    &::placeholder {
      font-family: $font-roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;

      color: #c4c4c4;
    }
  }
}
