.form-edit-category-rating {
  min-width: 90%;

  @media (min-width: 992px) {
    min-width: 410px;
    max-width: 454px;
    margin: 1.8rem 3.6rem 1rem 1.4rem;
  }

  h4 {
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 1rem;
    color: #000000;
  }

  .ant-form-item {
    .ant-form-item-control-input {
      min-height: 40px;
    }

    .ant-form-item-label {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      margin-bottom:8px;
      color: #000000;
    }

    .ant-form-item-control-input-content {
      input {
        height: 40px;
        border: 1px solid #c4c4c4;

        &::placeholder {
          font-family: $font-roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;

          color: #c4c4c4;
        }
      }

      .ant-select{
        margin:1.2rem 0 1.2rem;
        width: 161px;
      }
      .ant-select-selector {
        height: 32px;
       
        border-radius: 4px;
        border: 1px solid #D0D9E2;
        font-family: $font-helvetica-neue;

        input {
          height: 32px;
        }

      }
      textarea {
        min-height: 77px;
        border: 1px solid #c4c4c4;
        font-family: $font-roboto;

        &::placeholder {
          font-family: $font-roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;

          color: #c4c4c4;
        }
      }

      .ant-radio-wrapper {
        span {
          font-family: $font-roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;

          color: #5b5b5b;

          &.ant-radio-inner {
            border-color: #5e6871;

            &::after {
              background-color: #5e6871;
            }
          }
        }
      }
    }

    .ant-input {
      min-width: 100%;

      &:hover,
      &:active,
      .ant-input-focused {
        border-color: rgba(98, 200, 182, 1);
      }
    }
    .ant-select {
    

      &:hover,
      &:active
      {
        border-color: rgba(98, 200, 182, 1);
      }
    }
    label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      color: transparent;
    }
  }

  .submit-row {
    @media (min-width: 992px) {
      display: flex;
      padding-top: 2rem;
    }

    .btn {
      &--save {
        outline: none;
        border: none;
        background: #5e2eba;
        height: 40px;
        width: 100%;
        font-family: $font-helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;

        /* identical to box height */
        text-align: center;

        color: #ffffff;

        @media (min-width: 992px) {
          width: 196px;
        }
      }

      &--cancel {
        cursor: pointer;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        width: 100%;
     
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        height: 40px;
        line-height: 17px;
        text-decoration-line: underline;

        color: #000000;
  span{
    text-decoration: underline;
  }
        @media (min-width: 992px) {
          margin-left: 3.3rem;
          width: 50px;
        }
      }
    }
  }

  .ant-input-number-input {
    &::placeholder {
      font-family: $font-roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;

      color: #c4c4c4;
    }
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
    margin-right: 8px;
    .ant-checkbox+span {
      font-family: $font-roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;

      color: #5b5b5b;
    }

    .ant-checkbox {
      &::after {
        border: 1px solid #5e2eba;
        border-radius: 50%;
      }

      span {
        font-family: $font-roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        color: #5b5b5b;
      }

      .ant-checkbox-input {
        border-radius: 50%;
        background-color: white;
        border-color: #5e2eba;

        &:hover {
          border-radius: 50%;
          background-color: white;
          border-color: #5e2eba;
        }
      }

      .ant-checkbox-inner {
        border-radius: 50%;
        background-color: white;
        border-color: #5e2eba;

        &::after {
          border: 2px solid #5e2eba;
          border-top-color: #5e2eba;
          border-top-style: solid;

          border-left-color: #5e2eba;
          border-left-style: solid;

          border-top: 0;
          border-top-width: 0px;
          border-left: 0;
          border-left-width: 0px;
        }

        &:hover {
          background-color: white;
          border-color: #5e2eba;
        }
      }
    }
  }
  .form-certification{
    display: grid;
    align-items: center;
    grid-template-columns: auto 32px;
  }
 
  .ant-input:focus,
  .ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: rgba(98, 200, 182, 1) !important;
  box-shadow: none !important;

    outline: 0;
}
}