.ant-tabs-nav {
  .ant-tabs-tab {
    padding: 16px 24px;
  }

  .ant-tabs-tab-btn {
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #202223;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #761c61;
  }

  .ant-tabs-ink-bar {
    border-bottom: 3px solid #761c61;
  }

  &::before {
    border-bottom: 1px solid #e1e3e5;
  }
}
