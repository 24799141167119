.company-form,
.company-form-edit,
.company-detail-category {
  padding: 20.8px 2rem 1.5rem;
  .ant-select-multiple .ant-select-selection-item {
    border: 1px solid #5e2eba;
    height: 30px;
  }
  @media (min-width: 992px) {
    min-width: 646px;
  }
  .ant-select-selection-overflow-item {
    height: 40px;
  }
  h3 {
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    color: #000000;
  }

  h6 {
    font-family: $font-helvetica-neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    margin: 3.5rem 0 2rem 0;

    color: #000000;
  }

  .ant-form-item-label {
    label {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      /* identical to box height */

      color: #000000;

      &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: "";
      }
    }
  }

  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      input,
      .ant-select-selector {
        height: 40px;
        border: 1px solid #c4c4c4;
        font-family: $font-helvetica-neue;

        &::placeholder {
          font-family: $font-roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;

          color: #c4c4c4;
        }
      }
      textarea {
        min-height: 152px;
        border: 1px solid #c4c4c4;
        font-family: $font-helvetica-neue;
      }

      .ant-select-selector {
        height: 40px;
        padding-top: 2px;
        border: 1px solid #c4c4c4;
        font-family: $font-helvetica-neue;

        input {
          height: 40px;
        }
      }

      .ant-select-selection-placeholder {
        font-family: $font-roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        padding-top: 2px;

        color: #c4c4c4;
      }

      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        padding-top: 2px;
      }
    }
  }

  .dragger__container {
    display: flex;
    justify-content: center;

    .ant-upload-list {
      .ant-upload.ant-upload-select-picture-card {
        width: 440px;
        height: 252px;
        background: #f3f5f7;
        border: 1px solid #d0d9e2;
        box-sizing: border-box;
        border-radius: 4px;
      }

      .ant-upload-list-picture-card-container {
        width: 440px;
        height: 252px;

        .ant-upload-list-item {
          padding: 3rem;
        }
      }
    }

    p {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;

      color: #000000;
    }
  }

  .dragger__button {
    button {
      background: #5e2eba;
      font-family: $font-helvetica;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
      border: none;
      text-align: center;
      height: 40px;
      width: 196px;
      color: #ffffff;
      &:hover {
        opacity: 0.8;
      }
    }

    .ant-upload-list {
      display: flex;
      justify-content: center;

      .ant-upload.ant-upload-select-picture-card {
        height: 40px;
        width: 196px;
        border: none;
        border-radius: 0;
        margin: 2rem auto 4rem;
      }

      .ant-upload-list-picture-card-container {
        width: 440px;
        height: 252px;

        .ant-upload-list-item {
          padding: 3rem;
        }
      }
    }

    p {
      font-family: $font-helvetica-neue;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;

      color: #000000;
    }
  }

  .btns-row {
    margin: 2rem auto 4rem;
    max-width: 440px;
    display: flex;
    justify-content: space-around;

    .btn-upload {
      background: #5e2eba;
      font-family: $font-helvetica;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
      border: none;
      text-align: center;
      height: 40px;
      width: 196px;
      color: #ffffff;
      &:hover {
        opacity: 0.8;
      }
    }

    .btn-remove {
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      width: 44px;
      background-color: transparent;
      border: none;
      text-decoration-line: underline;
      cursor: pointer;
      color: #000000;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .submit-row {
    display: flex;
    .btn {
      &--save {
        cursor: pointer;
        height: 40px;
        width: 196px;
        background: #5e2eba;
        font-family: $font-helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        outline: none;
        border: none;
        text-align: center;

        color: #ffffff;
        &:hover {
          opacity: 0.8;
        }
      }
      &--cancel {
        cursor: pointer;
        height: 40px;
        font-family: $font-helvetica-neue;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        background-color: transparent;
        border: none;
        text-decoration-line: underline;
        margin-left: 3.25rem;
        color: #000000;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

 


}
.ant-form-item-explain {
  font-family: $font-roboto;
  font-size: 12px;
  margin-top: 2px;
}  
.ant-form-item-has-error .ant-input-number, .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #c4c4c4 !important;
}

