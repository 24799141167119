.input-search {
    max-width: 750px;

    span {
        .ant-input {
            background: #ffffff;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            font-family: $font-roboto;
            max-width: 541px;
            height: 40px;


            float: right;


            &::placeholder {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;

    

                color: #5b5b5b;
            }
            &:hover, &:active{
        border-color: rgba(98, 200, 182, 1);
            }
            &:focus{
                border-color: rgb(244, 250, 249);
                box-shadow: 0 0 0 .9px rgba(98, 200, 182, 1);
            }
        }

        .ant-input-group-addon {
            @media (min-width: 992px) {
                left: 13px !important;

            }
        }

        .ant-btn {
            background-color: #5e2eba;
            border: none;
            font-family: $font-helvetica;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            height: 40px;

            text-align: center;

            color: #ffffff;

            @media (min-width: 992px) {
                width: 196px;
            }
        }
    }

}