#form__addCategory
 {
  padding: 24px;


  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item-control-input-content {
    @media (min-width: 992px) {
    min-width: 376px;
    }
  }
  .ant-form-item-explain-error {
    font-size: 12px;
    font-family: $font-helvetica;
    margin-top: 2px;
  }
  .ant-input,
  .ant-select,
  .ant-form-item-label label {
    font-family: $font-roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-input,
  .ant-select {
    font-weight: 500;
    font-size: 12px;
max-width: 99.2%;
    color: #5B5B5B;
    height: 40px;
background: #FFFFFF;
border: 1px solid #C4C4C4;
box-sizing: border-box;
&:hover, &:active{
  border-color: rgba(98, 200, 182, 1);
      }
      &:focus{
          border-color: rgb(244, 250, 249);
          box-shadow: 0 0 0 .9px rgba(98, 200, 182, 1);
      }
    
  }
  .ant-form-item-label label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    
    /* identical to box height */
    
    color: #000000;
    
    
  }
  .input__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 24px;
    @media (max-width: 991px) {
 display: flex;
 flex-direction: column;
    }
    .form__additional-text {
        font-family: $font-helvetica;
      font-size: 14px;
      line-height: 22px;
      color: #9e9e9e;
      position: relative;
      bottom: 4px;
    }
  }

  .buttons_row {
    width: 60%;
    float: right;
    padding-top: 2rem;
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      float: none;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }

    #buttonform__addCategory
   {
      &--0 {
        background-color: white;
        border: 1.5px solid#5e2eba;
       
        font-family: $font-helvetica;

        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.75px;
        @media (min-width: 992px) {
        min-width: 169px;

        }
        color: #5e2eba;
        margin-right: 1rem;

        &:hover {
          opacity: 0.9;
        }
      }

      &--1 {
        background: #5e2eba;
 
        border: 1.5px solid #5e2eba;
        font-family: $font-helvetica;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.75px;
        @media (min-width: 992px) {
            min-width: 169px;
    
            }
        color: white;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}