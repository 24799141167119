.brand-detail-card {
  display: grid;
  grid-gap: 24px;

  grid-template-columns: 161px auto auto auto;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  .img {
    &__container {
      cursor: pointer;
      max-width: 161px;
      max-height: 161px;
      position: relative;

      &:hover {
        background-color: rgb(0, 0, 0);

        img {
          filter: opacity(28%);
        }

        .input-file {
          &__container {
            label {
              z-index: 2;
              display: flex;
            }
          }
        }
      }

      img {
        &:hover {
          background: rgb(0, 0, 0);
        }


        width: 161px;

        @media (max-width: 992px) {
          margin: auto;
        }
      }

      .input-file {
        &__container {
          display: flex;
          justify-content: center;
          margin: 5px auto;

          label {
            display: none;
            width: auto;
            height: auto;
            cursor: pointer;
            width: 100%;
            height: 100%;
            font-size: 14px;
            font-family: $font-helvetica;
            background: transparent;
            position: absolute;
            top: 0;
            align-items: center;
            justify-content: center;
            margin: auto;

            span {
              svg {
                width: 50px;
                height: 50px;
                color: white;
              }
            }
          }

          input {
            display: none;
          }
        }
      }
    }
  }

  div {
    h2 {
      font-family: $font-inter;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 0.5rem;
      line-height: 32px;

      color: #000000;
    }

    p {
      font-family: $font-inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: #000000;

      span {
        font-weight: normal;

        &.credentials {}
      }
    }

    span {
      &.space {
        display: block;
        height: 16px;
      }
    }
  }
  .default-settings{
    &__row{
display: flex;
align-items: center;
flex-direction: column;

      button{
        font-family: $font-inter;
font-style: normal;
font-weight: 600;
font-size: 16px;
        background: #5E2EBA;
        border:none;
border-radius: 4px;
width: 260px;
max-width: 100%;
min-height: 50px;
color:white;
@media (max-width: 992px) {
align-self: flex-start;
}
&:hover{
  opacity: .9;
}
&:first-of-type{
  margin-bottom: 11px;
}
      }

    }
  }
}

.brand-detail-card--pictureless {
  display: block;
  padding: 0 8px;
}