
.swal-modal{
    .swal-text{
color:#70757a;
font-size: 14px;
font-weight: bold;
font-family: $font-helvetica-neue; 
    }
    .swal-title {
        color: rgba(0,0,0,.65);
        font-weight: normal;
        font-family: $font-helvetica-neue; 
        font-size: 18px;
   
    }
    .swal-button{
        background-color: #5e2eba;
        border-radius: 0;
        font-family: $font-inter; 
        &:hover{
            background-color: #5e2eba;
            opacity: .9;
            box-shadow: none;
            outline: none;
            border:none;
        }
        &--danger{
            background-color: #C10042;
            &:hover{
                background-color: #C10042;
                opacity: .8;
            }
        }
    }
    .swal-icon{
        &--success{
            &__ring{
                border: 4px solid #5e2eba;;   
            }
            &__line{
                &--tip{
                    background-color: #5e2eba;
                }
                &--long{
                    background-color: #5e2eba;
                }
            }
        }
    
    }
}
