.img-picker-container {
    .image_picker {
        display: grid;
        grid-gap: 1.5rem;
        grid-template-columns: repeat(auto-fill, minmax(150px, 150px));
      
        .picker-container {
            display: flex;
            flex-direction: column;
            max-width: 150px;

            p {
                text-align: center;
                font-family: $font-helvetica-neue;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 140%;


                color: #000000;


            }
        }

        &--75{
            grid-template-columns: repeat(auto-fill, minmax(75px,75px));
            grid-gap:1rem;
            .picker-container {
             max-width: 75px;

             p{
                 font-size: 10px;
             }
            }
        }
    }

    button {
        background: #5E2EBA;
        border-radius: 4px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: white;
        border: none;
        height: 30px;
        cursor: pointer;

        width: 80px;
        margin: 2.5rem 6px 2rem;
        &:hover{
            opacity: .9;
        }
    }
}