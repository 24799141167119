aside {
  background: black;
  color: #ffffff;
  min-height: 100vh;
  .ant-layout-sider-children {
    background-color: #ffffff;
    ul {
      width: 100.5%;
    }
    ul,
    li {
      background-color: #ffffff;
    }

    li {
      padding-left: 10px;
      width: 100% !important;
      font-family: $font-inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      padding-left: 28px !important;
      margin-bottom: 0;
      &:hover {
        color: #4d4b4b;
      }
      &::after {
        border: none !important;
      }
      .ant-menu-submenu-title {
        padding-left: 0px !important;
        &:hover {
          color: #4d4b4b;
        }
        .ant-menu-submenu-arrow {
          &::after,
          &::before {
            background-color: purple;
            &:focus,
            &:active,
            &:hover {
              color: #761c61;
              background-color: purple;
            }
          }
        }
      }
      &.ant-menu-item-selected,
      &.active {
        background-color: white !important;
        color: #761c61;
        opacity: 1;
        font-weight: 600;
      }
      &.logout-item {
        opacity: 1;
        display: flex;
        flex-direction: row;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        padding-left: 0 !important;
        margin-top: calc(100vh - 400px);
        img {
          margin-right: 13px;
          &:hover {
            opacity: 0.5;
          }
        }
        &:active {
          background-color: black;
        }
      }
    }

    .logo {
      &__container {
        width: 100%;
        display: flex;
        margin: 1.2rem 0 3rem;

        img {
          padding-left: 28px;
        }
      }
    }
  }
  .ant-menu-sub.ant-menu-inline {
    background-color: white;
  }
}

aside.brand {
  .ant-layout-sider-children {
    background-color: $brand_color_background !important;
  }
  ul,
  li {
    background-color: $brand_color_background !important;
  }
  li.active {
    background-color: $brand_color_background !important;
  }
}

section {
  &.ant-layout {
    background: #f8f8f9;
    main {
      section {
        &.container {
          min-height: 90vh;
          padding: 2.5rem 5%;
          &--detail {
            min-height: 10vh;
            padding: 2.5rem 0;
          }
        }
      }
    }
  }
}

.wrapper-log-out {
  padding-left: 28px !important;
  position: absolute;
  bottom: 0;
  margin: 1rem 0;
}

.log-out {
  cursor: pointer;
  color: black;
  display: flex;
  /* flex-direction: row; */
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  justify-content: center;

  & > img {
    margin-right: 13px;
  }
}
