.login {
  background: #e5e5e5;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__container {
    background-color: white;
    border: 1px solid #d3dbe2;
    box-sizing: border-box;
    border-radius: 16px;
    width: 648px;
    height: 554px;
    display: flex;
    padding-top: 4rem;
    align-items: center;
    flex-direction: column;
    .auth-failed-message{
      font-family: $font-helvetica-neue;
      font-weight: 600;
      font-size: 14px;
      line-height: 1em;
      text-align: center;
      color: #4E6863;
      padding-bottom: 16px;
      border-bottom: 1px solid #c4c4c4;
    }
    @media (max-width: 991px) {
      width: 90%;
      max-width: 648px;
      margin: 2rem 1rem;
    }
    .login-form {
      @media (max-width: 991px) {
        max-width: 90%;
      }
      .ant-form-item-explain.ant-form-item-explain-error {
        color: #ff4d4f;
        font-family: $font-inter;
        font-size: 12px;
        min-height: none;
      }
      .login-input {
        width: 482px;
        @media (max-width: 991px) {
          max-width: 100%;
        }
        margin-bottom: 2rem;
        .ant-form-item-label {
          padding-bottom: 2px;
          label {
            font-family: $font-inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #70757a;
            &:before {
              content: "";
            }
          }
        }
        input {
          font-family: $font-inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #70757a;
        max-width: 99.6%;
          margin: 0 1px;
          height: 40px;
          border: 1px solid #d9d9d9;
          box-sizing: border-box;
          border-radius: 4px;
          &::placeholder {
            color: #9e9e9e;
          }
          &:hover, &:active{
            border-color: rgba(98, 200, 182, 1);
                }
                &:focus{
                    border-color: rgb(244, 250, 249);
                    box-shadow: 0 0 0 .9px rgba(98, 200, 182, 1);
                }
        }
      }
      .login-form-button {
        margin-top: 2rem;
        max-width: 482px;
        width: 100%;
        background: #000000;
        border-radius: 4px;
        font-family: $font-inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.75px;
        color: #ffffff;
        height: 45px;
        @media (max-width: 991px) {
          max-width: 100%;
        }
      }
    }
  }

  &__logo {
    max-width: 100%;
    margin-bottom: 4rem;
  }
}
