.UploadModal {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-title {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        color: black !important;
        text-decoration: none;
      }

      .ant-modal-body {
        .AvatarEditor {
          display: flex;
          justify-content: center;
          margin: 15px;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 130%;
          color: black !important;
          text-decoration: none;
          .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 18px;
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            -webkit-transition: 0.2s;
            transition: opacity 0.2s;
          }
          .slider:hover {
            opacity: 1;
          }

          .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            background: #a77f43;
            cursor: pointer;
          }

          .slider::-moz-range-thumb {
            width: 25px;
            height: 25px;
            background: #4caf50;
            cursor: pointer;
          }
          input {
            padding: 0px;
          }
        }
      }
      .ant-modal-footer {
        .save {
          min-width: 196px;
          height: 40px;
          font-family: Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #ffffff;
          background: #5e2eba;
          border: none;
          box-shadow: none;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
