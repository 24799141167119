@import "~antd/dist/antd.css";

*, *:before, *:after{
    box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
    --antd-wave-shadow-color:none;
    font-family: $font-inter;
}

footer, header, nav, section, main{
    display: block;
}

body{
    line-height: 1;
}

ol, ul{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
    content: '';
    content: none;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}

input ,.ant-input-affix-wrapper{
    -webkit-appearance: none;
    border-radius: 0;
}
.ant-input-affix-wrapper{
    padding:0 11px;
    border-radius: 2px;
}
.ant-input:focus,
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector ,.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper:hover{
border-color: rgb(134, 98, 200) !important;
box-shadow: none !important;

  outline: 0;
}

.ant-form-item-label  {
    label{
        &.ant-form-item-required:not(.ant-form-item-required-mark-optional){
            &::before{
                content: '';
            }
        }
    }  
}
    
.ant-form-item-explain.ant-form-item-explain-error {
    position: relative;
    top: 5px;
    color: $orange-inspired;
    font-family: $font-inter;
    font-size: 12px;
    min-height: none;
  }

  .ant-form-item-has-error {

    border-color: $orange-inspired;
}