

.ant-checkbox-wrapper{
    &:hover{
        border-color: #5E2EBA;
    }
}
.ant-checkbox {
    &::after {
      border: 1px solid #5E2EBA;
      border-radius: 4px;
    }

    span {
      font-family: $font-roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;

      color: #5E2EBA;
    }

    .ant-checkbox-input {
        border-radius: 4px;
      background-color: white;
      border-color: #5E2EBA;

      &:hover {
        border-radius: 4px;
        background-color: white;
        border-color: #5E2EBA;
      }
    }

    .ant-checkbox-inner {
        border-radius: 4px;
      background-color: white;
      border-color: #5E2EBA;

      &::after {
        border: 2px solid #5E2EBA;
        border-top-color: #5E2EBA;
        border-top-style: solid;

        border-left-color: #5E2EBA;
        border-left-style: solid;

        border-top: 0;
        border-top-width: 0px;
        border-left: 0;
        border-left-width: 0px;
      }

      &:hover {
        background-color: white;
        border-color: #5E2EBA;
      }
    }
  }