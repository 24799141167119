.Tags {
  .ant-tag {
    background-color: #b167b9;
    border-radius: 15px;
    color: white;
    padding: 2px 7px;
    margin: 2px;
  }

  .ant-tag > span {
    color: white;
  }
  p {
    font-size: 12px;
    padding-right: 10px;
    display: inline-block;
    font-weight: bold;
  }
}

.table-picker {
  margin-top: 15px;
  .logo {
    width: 35px;
    height: 35px;
    vertical-align: middle;
    object-fit: contain;
  }

  .more-info:hover {
    color: red;
  }
  .ant-table-tbody > tr {
    height: 60px;
  }

  .ant-table-container .ant-table-content table tbody tr td {
    vertical-align: top;
  }
}

.popover-content {
  max-width: 375px;
  .ant-popover-content {
    border: 1px solid #dfdede;
  }
  .ant-popover-title {
    border: none;
    text-transform: uppercase;
    padding-top: 20px;
    padding-left: 20px;
    font-weight: 100;
    font-size: 12px;
  }
  .ant-popover-inner-content {
    padding: 10px 20px 20px 20px;
    font-weight: bold;
    font-size: 12px;
  }
}
